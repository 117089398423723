import React from 'react';
import styled from 'styled-components';
import { rem, position, rgba } from 'polished';
import GatsbyImage from 'gatsby-image';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Headline } from './Typography';
import { colors } from '../helpers/variables';
import { mq } from '../helpers/stylehelpers';
import { fluidImageType } from '../helpers/prop-types';

/** Wrapper um die Komponente */
const Wrapper = styled.div`
    background: ${colors.gray100};
    position: relative;
    width: 100%;
    z-index: 0;

    ${mq.large`
        padding-top: ${rem(25)};
        &:after {
            display: block;
            ${position('absolute', null, null, 0, 0)};
            content: '';
            background-color: ${colors.white};
            height: ${rem(25)};
            width: 100%;
            z-index: 1;
        }

    `};
`;

/** Innerer Container */
const Inner = styled.div`
    position: relative;
    margin: 0 auto;
    /* max-width: ${rem(1820)}; */
`;

/** Seitentitel */
const Title = styled(Headline)`
    color: ${colors.white};
    ${position('absolute', '50%', null, null, 0)};
    background-color: ${colors.primary};
    padding: 0.25em 0.5em;
    margin: 0;
    border-radius: 0 ${rem(20)} ${rem(20)} 0;
    transform: translateY(-50%);

    display: inline-block;
    z-index: 2;

    ${mq.large`
        ${position('absolute', 'auto', 'auto', 0, 0)};
        margin-left: 2%;
        transform: translateY(-120%);
    `};
`;

/** Keysivual */
const Img = styled(GatsbyImage)`
    width: 100%;
    z-index: 2;

    ${mq.large`
        margin: 0 auto;
        box-shadow: 0px 0px 10px ${rgba(colors.black, 0.25)};
        width: 96%;
    `};
`;

/**
 * Stage Komponente (Keyvisual + Title) für die Contentseiten
 * @param {fluidImageType} props.image Hintergrundbild
 * @param {string} props.title Titel des Keyvisuals
 */
const Stage = ({ title, image }) => (
    <Wrapper>
        <Inner>
            <Img fluid={image.fluid} />
            <Title level="h1">{title}</Title>
        </Inner>
    </Wrapper>
);

Stage.propTypes = {
    title: PropTypes.string.isRequired,
    image: fluidImageType.isRequired,
};

export default Stage;

/**
 * GraphQL Fragment für GatsbyImage
 * @type {GraphQL}
 */
// eslint-disable-next-line no-undef
export const query = graphql`
    fragment StageFluid on File {
        childImageSharp {
            fluid(maxWidth: 1820, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`;
